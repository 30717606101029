<template>
  <div class="add-candidate">
    <Registration :customer="2" @submit="create" />
    <!-- <CToaster > -->
    <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
      <CToast :class="toastClass" :show="true">{{ toastMessage }} </CToast>
    </CToaster>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '@/router'
import Registration from "@/containers/CandidateProfile/Registration";

export default {
  components: {
    Registration
  },
  computed: {
    ...mapGetters([
      'toast',
      'toastMessage',
      'toastClass',
      'toastHide',
      'candidateProfile',
      'getUserDetails'
    ])
  },
  watch: {
    candidateProfile(profile) {
      if (profile && profile.candidate_uid) {
        router.push({ path: 'candidate-profile', query: { id: profile.candidate_uid } })
      }
    }
  },
  methods: {
    create(candidate) {
      this.$store.dispatch("createCandidate", candidate)
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 50%;
    margin: auto;
  }
}
</style>
