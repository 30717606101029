<template>
  <div class="registration container-md col-lg-8 col-md-8">
    <LoadingOverlay v-if="isLoading" />
    <div class="d-flex flex-row justify-content-between">
      <div class>
        <h3 class="pb-3 px-1 mb-0 main-title text-primary">
          Quick Registration
        </h3>
      </div>
    </div>
    <ValidationObserver ref="register" v-slot="{ handleSubmit }">
      <form
        id="register"
        @submit.prevent="handleSubmit()"
        v-on:keypress.enter.prevent
        autocomplete="false"
      >
        <CCard>
          <CCardBody>
            <CRow>
              <h4 class="pb-3 d-block d-md-none main-title text-primary">
                Personal Details
              </h4>
              <div class="col-md-5 col-lg-4 col-xl-3 mb-3">
                <div class="avatar-upload">
                  <div class="avatar-preview">
                    <div :style="{ backgroundImage: 'url(' + url + ')' }"></div>
                  </div>
                  <div class="avatar-edit">
                    <input
                      id="imageUpload"
                      type="file"
                      ref="profilefileref"
                      name="image"
                      accept="image/*"
                      @change="selectedFile"
                    />
                    <label
                      for="imageUpload"
                      class="p-2 text-primary font-weight-bold"
                      >Upload Photo</label
                    >
                  </div>
                  <div v-if="isImageSelected">
                    <UploadDocument
                      class="mt-4"
                      :fileData="fileData"
                      v-on="$listeners"
                      :isProfilePic="true"
                      @closeModal="closeModal"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-lg-8 col-xl-9">
                <h4
                  class="
                    pb-3
                    main-title
                    text-primary
                    d-none d-md-block d-md-none
                  "
                >
                  Personal Details
                </h4>
                <CRow name="title">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Title</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="title_id"
                            @input="handleChangeSelect"
                            :value="candidate.title_id"
                            :options="
                              options && options['titles']
                                ? options['titles']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="first_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >First Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="first_name"
                            :value="candidate.first_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow name="middlename" class="mb-3">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12">Middle Name(s)</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="middle_name"
                            :value="candidate.middle_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow name="surname" class="row">
                      <label class="required col-lg-12 col-md-12"
                        >Last Name</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required|alpha_spaces"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="surname"
                            :value="candidate.surname"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="12">
                    <CRow name="date_of_birth" class="row pb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Date of Birth</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                        <ValidationProvider
                          name="dob"
                          rules="required|date_validate|no_future_date"
                          v-slot="{ errors }"
                        >
                          <DateInput
                            :onlyMonthAndYear="false"
                            name="dob"
                            :value="candidate.dob"
                            @input="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12">Gender</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="gender_id"
                            :value="candidate.gender_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['gender']
                                ? options['gender']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Nationality</label
                      >
                      <div class="col infoicon-multi-select">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            :name="`nationality_ids`"
                            @input="handleChangeMultiSelectAsArray"
                            :value="candidate.nationality_ids"
                            :options="
                              options && options['nationality']
                                ? options['nationality']
                                : []
                            "
                            option_label="nationality"
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="position-relative multi-select-icon-div">
                        <CIcon
                          name="cil-info"
                          class="hinticon-multi-select"
                          v-c-tooltip="'Select one or more'"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Currently Working In</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <Select
                          :name="`country_id`"
                          @input="handleChangeSelect"
                          :value="candidate.country_id"
                          :options="
                            options && options['locationInfo']
                              ? options['locationInfo']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="true"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow v-if="payload.country_id == '181'">
                  <CCol md="12" lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Do you have an Emirates ID?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="has_emirates_id"
                            :value="candidate.has_emirates_id"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6" v-if="payload.has_emirates_id">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12">Emirates ID</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          :rules="{ emirates_id: 15 }"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="emirates_id"
                            :value="candidate.emirates_id"
                            @change="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Local License/Eligibility</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="local_license"
                            :value="candidate.local_license"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >DataFlow Complete?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="dataflow_complete"
                            :value="candidate.dataflow_complete"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="6" v-if="payload.local_license">
                    <CRow class="mb-3">
                      <label
                        name="lbl_eligibility_letter_available_for"
                        class="required col-lg-12 col-md-12"
                        >License Authority</label
                      >
                      <div class="col infoicon-multi-select">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="eligibility_letter_available_for"
                            @input="handleChangeMultiSelectAsString"
                            :value="candidate.eligibility_letter_available_for"
                            :options="
                              options && options['eligibility']
                                ? options['eligibility']
                                : []
                            "
                            :taggable="false"
                            :multiple="true"
                            :clearable="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6" v-if="isDoHAuthority">
                    <CRow class="row mb-3" ref="license">
                      <label class="col-lg-12 col-md-12">License Number</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          name="license_number"
                          ref="license_number"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            ref="license_no"
                            name="license_number"
                            :value="candidate.license_number"
                            @change="handleInput"
                            @input="handleInputUpper"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol lg="6">
                    <CRow class="row mb-3">
                      <label class="col-lg-12 col-md-12">Passport Number</label>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="passport_number"
                          :value="candidate.passport_number"
                          @change="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </div>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <h4 class="main-title text-primary pb-3">Contact Information</h4>
            <CRow>
              <CCol md="6">
                <CRow name="phone" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Phone Number</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="phone"
                      :rules="{
                        required: true,
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInput
                        name="phone"
                        :value="candidate.phone"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="phoneNo"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow
                  name="isRegisteredWhatsapp"
                  class="row mb-3 registered-whatsapp"
                >
                  <label class="col-lg-5 col-md-5 col-8 registered-whatsapp"
                    >Registered in WhatsApp</label
                  >
                  <CSwitch
                    name="isRegisteredWhatsapp"
                    color="success"
                    :checked="candidate.isRegisteredWhatsapp"
                    shape="pill"
                    variant="3d"
                    :disabled="
                      candidate.preferred_contact_method.some(
                        (el) => el.id == 'whatsapp'
                      )
                    "
                    class="ml-2 registered-whatsapp"
                    @change.native="
                      handleChangeCheck('isRegisteredWhatsapp', $event)
                    "
                  />
                </CRow>
              </CCol>
              <CCol v-if="candidate.isRegisteredWhatsapp" md="6">
                <CRow name="whatsapp" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required">WhatsApp</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      ref="whatsapp"
                      :rules="{
                        phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        required: true,
                      }"
                      v-slot="{ errors }"
                    >
                      <PhoneInputwithCountry
                        name="whatsapp"
                        :value="candidate.whatsapp"
                        :options="
                          options && options['dialingCode']
                            ? options['dialingCode']
                            : []
                        "
                        @change="handleInput"
                        :error="errors[0]"
                        ref="whatsappNo"
                      />
                      <small class="hint">
                        Please provide the phone number registered with WhatsApp
                        here.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="preferred_contact_method" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Preferred Contact Method</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      name="preferred_contact_method"
                      :value="candidate.preferred_contact_method"
                      :options="
                        options && options['preferred_contact_method']
                          ? options['preferred_contact_method']
                          : []
                      "
                      @input="handleChangeSelect"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                    />
                  </div>
                  <div class="position-relative multi-select-icon-div">
                    <CIcon
                      name="cil-info"
                      class="hinticon-multi-select"
                      v-c-tooltip="'Select one or more'"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="email" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Email</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="email" v-slot="{ errors }">
                      <TextInput
                        name="alternate_email"
                        :value="candidate.alternate_email"
                        @change="handleInput"
                        :error="errors[0]"
                        autocomplete="new-email"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="!isNurse && !isAHP">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Profession</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Highest Qualification Obtained In</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_country_id"
                        :value="candidate.main_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['locationInfo']
                            ? options['locationInfo']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Qualification Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_id"
                        :value="candidate.main_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['qualification']
                            ? options['qualification']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6">
                <CRow>
                  <CCol lg="12" v-if="mainQualificationIsOther">
                    <CRow name="other_qualification_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Other Qualification (Level / Subject)</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="other_qualification_name"
                            :value="candidate.other_qualification_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol
                md="6"
                v-if="
                  candidate.main_qualification_id &&
                  candidate.main_qualification_id.allow_subject_input == true
                "
              >
                <CRow name="qualification_subject_name" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Qualification Subject</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="main_qualification_description"
                      :value="candidate.main_qualification_description"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="12">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Month / Year Awarded</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="no_future_date|dob_check:@dob|required"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="qualified_date"
                        :value="candidate.qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="options['subSpeciality'].length">
                <CRow name="sub_speciality" class="row mb-3">
                  <label class="col-lg-12 col-md-12">Sub Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="sub_speciality_id"
                      :value="candidate.sub_speciality_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard v-if="isNurse || isAHP">
          <CCardBody>
            <h4 class="main-title text-primary pb-3">
              Professional Information
            </h4>
            <CRow>
              <CCol md="6">
                <CRow name="candidate_type" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Profession</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="candidate_type_id"
                        :value="candidate.candidate_type_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['candidateType']
                            ? options['candidateType']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Nurse/Midwifery Level</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="level_id"
                        :value="candidate.level_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['nurseLevel']
                            ? options['nurseLevel']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :showTooltip="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Highest
                    {{
                      isNurse
                        ? "Nurse/Midwife Qualification"
                        : "Medical Qualification"
                    }}
                    Obtained In</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_country_id"
                        :value="candidate.main_qualification_country_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['locationInfo']
                            ? options['locationInfo']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Highest Qualification Name</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="main_qualification_id"
                        :value="candidate.main_qualification_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['qualification']
                            ? options['qualification']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <!-- <CCol
                md="6"
                v-if="
                  candidate.first_qualification_id &&
                  candidate.first_qualification_id.allow_subject_input ==
                    true &&
                  isAHP
                "
              > -->
              <CCol
                md="6"
                v-if="
                  candidate.main_qualification_id &&
                  candidate.main_qualification_id.allow_subject_input == true
                "
              >
                <CRow name="main_qualification_subject_name" class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Qualification Subject</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <TextInput
                      name="main_qualification_description"
                      :value="candidate.main_qualification_description"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6" v-if="mainQualificationIsOther">
                <CRow name="main_other_qualification_name" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Qualification (Level / Subject)</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_qualification_name"
                        :value="candidate.other_qualification_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Month / Year Awarded</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="no_future_date|dob_check:@dob|required"
                      v-slot="{ errors }"
                    >
                      <DateInput
                        :onlyMonthAndYear="true"
                        name="qualified_date"
                        :value="candidate.qualified_date"
                        @change="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isNurse && isPGQualification">
                <CRow name="registered_as" class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Are you Licensed/Registered as a Speciality Nurse or
                    (Advanced) Nurse Practitioner?</label
                  >
                  <CCol md="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <RadioButton
                        name="nurse_registration_type"
                        :value="candidate['nurse_registration_type']"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleRadioNurseRegType"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Speciality</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="speciality_id"
                        :value="candidate.speciality_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['speciality']
                            ? options['speciality']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol lg="6" v-if="specialityIsOther">
                <CRow name="other_speciality" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Other Speciality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_speciality"
                        :value="candidate.other_speciality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6" v-if="isNurse">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="
                        options && options['speciality']
                          ? options['speciality']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6" v-if="isAHP && options['subSpeciality'].length">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Sub Type / Licensed as</label
                  >
                  <div class="col infoicon-multi-select">
                    <Select
                      :name="`sub_speciality_id`"
                      @input="handleChangeSelect"
                      :value="candidate.sub_speciality_id"
                      :options="
                        options && options['subSpeciality']
                          ? options['subSpeciality']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="false"
                      :clearable="true"
                    />
                  </div>
                </CRow>
              </CCol>
              <!-- <CCol md="6" v-if="isAHP">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Other areas of expertise<small
                      class="text-primary ml-1 font-weight-bold"
                      >(select max 3)</small
                    ></label
                  >
                  <div class="col-lg-12 col-md-12">
                    <Select
                      :name="`other_areas_of_expertise_ids`"
                      @input="handleChangeMultiSelectAsArray"
                      :value="candidate.other_areas_of_expertise_ids"
                      :options="
                        options && options['specialInterest']
                          ? options['specialInterest']
                          : []
                      "
                      option_label="label"
                      :taggable="false"
                      :multiple="true"
                      :clearable="true"
                      :limit="3"
                    />
                  </div>
                </CRow>
              </CCol> -->
              <CCol md="6" v-if="isOther">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12 required"
                    >Other areas of expertise Name</label
                  >
                  <div class="col-lg-12 col-md-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="other_areas_of_expertise_name"
                        @input="handleInput"
                        :value="candidate.other_areas_of_expertise_name"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardBody>
            <h4 class="main-title text-primary pb-3">Registration Details</h4>
            <CRow name="email" class="row mb-3">
              <label class="required col-lg-12 col-md-12">Email</label>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                  <TextInput
                    name="email"
                    :value="candidate.email"
                    @input="handleInput"
                    @change="handleEmailChange"
                    :error="errors[0]"
                    autocomplete="login-email"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow>
              <CCol md="6">
                <CRow name="password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12">Password</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      name="password"
                      :rules="{
                        required: true,
                        password_length: 8,
                        password_strength:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      }"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="password"
                        :value="candidate.password"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="new-password"
                      />
                      <small class="hint">
                        Password format: 8 characters - containing upper & lower
                        case letters, numbers and a special character. No words.
                      </small>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow name="confirm_password" class="row mb-3">
                  <label class="required col-lg-12 col-md-12"
                    >Confirm Password</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider
                      rules="required|confirmed:password"
                      v-slot="{ errors }"
                    >
                      <PasswordInput
                        name="confirmPassword"
                        :value="candidate.confirmPassword"
                        @input="handleInput"
                        :error="errors[0]"
                        autocomplete="confirm-password"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <h5 class="text-primary">
              Please Upload your CV and other Documents
            </h5>
            <CRow class="row mb-3 border-top mt-4 pt-3 pb-1">
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12"
                    >Select Document Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="document_type"
                      :value="candidate.document_type || 'CV / Resume'"
                      @input="handleChangeSelect"
                      :options="
                        options && options['document_type']
                          ? options['document_type']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label class="col-lg-12 col-md-12">Upload Document</label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <input
                      name="upload-document"
                      type="file"
                      ref="docfileref"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow
              class="
                ml-2
                mr-2
                d-flex
                align-items-center
                documentScrolling
                document-table
              "
              v-if="uploadFiles.length !== 0"
            >
              <table class="table">
                <thead>
                  <tr>
                    <th class="w-25">Document Type</th>
                    <th>Document Name</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in uploadFiles" :key="index">
                    <td>{{ data.docType.label }}</td>
                    <td>
                      {{ data.file.name }}
                      <button
                        v-if="
                          ['PNG', 'JPEG', 'JPG', 'GIF', 'PDF'].includes(
                            data.fileExt
                          )
                        "
                        class="hand-icon action-button"
                        @click="preview(data)"
                      >
                        <i class="fas fa-eye ml-3"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        class="hand-icon action-button"
                        @click="deleteDocuments(data.file.name, index)"
                      >
                        <i class="fas fa-trash ml-3"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <div>
              <input
                type="checkbox"
                class="mr-2"
                ref="termPrivacyCheck"
                :checked="isTermPrivacyChecked"
                @change="isTermPrivacyChecked = !isTermPrivacyChecked"
              />
              <span v-if="!isMobile"
                >I have read and agree to the
                <strong>
                  <a href="javascript:void(0)" @click="showTermPopup"
                    >Terms and Privacy Policy</a
                  ></strong
                >. By registering your profile and personal details you consent
                and agree to receiving updates and information relating to work
                opportunities. You have the option to
                <strong>unsubscribe</strong> at any time.
              </span>
              <span v-else>
                I have read and agree to the
                <strong>
                  <a href="javascript:void(0)" @click="showTermPopup"
                    >Terms and Privacy Policy</a
                  ></strong
                >
              </span>
            </div>
          </CCardBody>
        </CCard>
        <div
          class="text-center mx-0 mt-2 pt-4 pb-4"
          style="padding-bottom: 150px !important"
        >
          <button
            type="button"
            name="registration-home"
            class="
              btn
              rounded-0
              px-5
              btn-lg
              m-1
              mt-3
              float-left
              btn-outline-primary btn-lg
              mr-2
            "
            @click="goToLogin()"
          >
            Home
          </button>
          <button
            name="registration-cancel"
            class="btn rounded-0 px-5 btn-secondary btn-lg m-1 mt-3"
            type="button"
            @click="resetForm"
          >
            Clear All
          </button>
          <button
            name="registration-submit"
            class="btn rounded-0 px-5 btn-primary btn-lg m-1 mt-3"
            type="submit"
            @click="onSubmit"
            :disabled="isSubmitted"
          >
            Submit
            <CSpinner
              v-if="isSubmitted"
              class="spinner-border-sm text-white m-1"
            />
          </button>
        </div>
      </form>
    </ValidationObserver>
    <CToaster v-if="isFileError" :autohide="3000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >File too Big, please select a file less than 2mb</CToast
      >
    </CToaster>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="emailConfirmModal.modalTitle"
      :modalColor="emailConfirmModal.modalColor"
      :modalContent="emailConfirmModal.modalContent"
      :isShowPopup="emailConfirmModal.isShowPopup"
      :buttons="emailConfirmModal.buttons"
      :modalCallBack="emailmodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="termConfirmModal.modalTitle"
      :modalColor="termConfirmModal.modalColor"
      :modalContent="termConfirmModal.modalContent"
      :isShowPopup="termConfirmModal.isShowPopup"
      :buttons="termConfirmModal.buttons"
      :modalCallBack="termmodalCallBack"
      :closeOnBackdrop="false"
    />
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import _ from "lodash";
import Vue from "vue";
import m from "moment";
import pdf from "vue-pdf";
import { extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  regex,
  min,
  numeric,
  alpha_spaces,
} from "vee-validate/dist/rules";
import PreviewModal from "@/components/reusable/PreviewModal";
import { mapGetters, mapActions } from "vuex";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters",
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters",
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password",
});
extend("email", { ...email, message: "Invalid email" });
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("alpha_spaces", {
  ...alpha_spaces,
  message: "Only Alphabets are allowed",
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
extend("dob_check", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    if (!(input_date < birth_date)) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("emirates_id", {
  ...min,
  message: "Emirates ID be atleast 15 characters",
});

import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import DateInput from "@/components/reusable/Fields/DateInput";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Modal from "@/components/reusable/Modal";
import { LOGIN_URL } from "../../helpers/helper";
import PasswordInput from "@/components/reusable/Fields/PasswordInput";

export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    PhoneInputwithCountry,
    RadioButton,
    DateInput,
    UploadDocument,
    Modal,
    PreviewModal,
    PasswordInput,
  },
  props: {
    customer: {
      type: Number,
      default: null,
    },
    organisationID: {
      type: Number,
    },
    locationID: {
      type: Number,
    },
    profilePhoto: {},
    isSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isFileError: false,
      confirmPassword: null,
      candidate: {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        local_license: false,
        dataflow_complete: false,
      },
      payload: {
        customer_id: this.customer,
        requires_login: true,
        volunteer: false,
        retired_clinician: false,
        local_license: false,
        dataflow_complete: false,
      },
      isImageSelected: false,
      fileData: {},
      url: "/img/user_default_pic.png",
      uploadFiles: [],
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      emailConfirmModal: {
        modalColor: "danger",
        modalTitle: "Email Confirmation",
        modalContent:
          "Is this your contact email as it is different from above. Please confirm...",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      document_id: "",
      acquired_date: null,
      previewModal: {
        modalColor: "primary",
        modalTitle: "",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      isTermPrivacyChecked: false,
      termConfirmModal: {
        modalColor: "danger",
        modalTitle: "Terms and Privacy Policy",
        modalContent: "Please accept Terms and Privacy Policy",
        isShowPopup: false,
        buttons: ["OK"],
      },
      isMobile: false,
      licenseValidation: {
        is_license_valid: null,
        isValidating: false,
        isValid: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "gender",
      "locationCountryInfo",
      "nationalityCountryInfo",
      "candidateType",
      "specialityFilterByType",
      "specialityByTypeAndCountry",
      "subSpecialityFilterBySpeciality",
      "qualificationByCountryAndCandidateType",
      "uploaded",
      "documentTypeExcPhoto",
      "dialingCodeWithCountryCode",
      "titles",
      "getSkillsByCandidateType",
      "candidateLevelFilterByTypeCountry",
      "specialInterestFilterAHP",
      "firstQualificationByCountryAndCandidateType",
      "higherQualificationByCountryAndCandidateType",
    ]),
    documentTypeExcPhotoCustomOrder() {
      return (
        (this.documentTypeExcPhoto &&
          this.documentTypeExcPhoto?.length &&
          this.documentTypeExcPhoto.sort((a, b) =>
            a.label === "CV / Resume" ? -1 : 1
          )) ||
        []
      );
    },
    nationalityOptions() {
      return (
        (this.nationalityCountryInfo &&
          this.candidate &&
          this.candidate["nationality_ids"] &&
          this.nationalityCountryInfo.filter((val) => {
            return !this.candidate["nationality_ids"].some(
              ({ code }) => code === val.code
            );
          })) ||
        this.nationalityCountryInfo
      );
    },
    options() {
      return {
        gender: this.gender || [],
        locationInfo: this.locationCountryInfo || [],
        nationality: this.nationalityOptions || [],
        titles: this.titles || [],
        dialingCode: this.dialingCodeWithCountryCode || [],
        candidateType: this.candidateType || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.payload.candidate_type_id,
            country_id: this.payload.main_qualification_country_id,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.payload.speciality_id,
          }) || [],
        qualification: this.qualificationByCountryAndCandidateType || [],
        document_type: this.documentTypeExcPhoto || [],
        preferred_contact_method: [
          { id: "phone", label: "Phone" },
          { id: "whatsapp", label: "WhatsApp" },
          { id: "email", label: "Email" },
          { id: "sms", label: "SMS" },
        ],
        document_verification_status: [
          { id: "none", label: "None" },
          { id: "some", label: "Some" },
          { id: "all", label: "All" },
          { id: "n/a", label: "N/A" },
        ],
        licence:
          this.specialityByTypeAndCountry({
            candidateType: this.payload.candidate_type_id,
            country_id: this.payload.first_qualification_country_id,
          }) || [],
        skills:
          this.getSkillsByCandidateType({
            candidate_type_id: this.payload.candidate_type_id,
          }) || [],
        departments: this.getDepartmentsByOrganisationId || [],
        nurseLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.payload?.candidate_type_id,
            country_id: null,
          }) || [],
        specialInterest: this.specialInterestFilterAHP({
          candidate_type_id: this.payload.candidate_type_id,
          speciality: null,
        }),
        firstQualification: this.firstQualificationByCountryAndCandidateType,
        higherQualification: this.higherQualificationByCountryAndCandidateType,
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        eligibility: [
          { code: "DHA", label: "DHA" },
          { code: "MoH", label: "MoH" },
          { code: "DOH", label: "DOH" },
          { code: "DHCA", label: "DHCA" },
        ],
      };
    },
    fileType() {
      if (this.candidate.document_type && this.candidate.document_type.code) {
        let fileext = this.documentTypeExcPhoto
          .filter(({ code }) => code === this.candidate.document_type.code)
          .map((d) => d.allowed_extensions)[0];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      } else {
        let fileexts = this.documentTypeExcPhoto
          .filter(({ code }) => code === 1)
          .map((d) => d.allowed_extensions);
        let fileext = fileexts?.length ? fileexts[0] : [];
        fileext = fileext.map((d) => d.toLowerCase());
        return this.arraytoComma(fileext);
      }
    },
    isNurse() {
      return this.candidate?.candidate_type_id?.code
        ? this.candidate.candidate_type_id.code === 2
        : false;
    },
    isAHP() {
      return this.candidate?.candidate_type_id?.code === 3 || false;
    },
    isDentist() {
      return this.candidate?.candidate_type_id?.code === 4 || false;
    },
    firstQualificationIsOther() {
      return (
        this.candidate?.first_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    mainQualificationIsOther() {
      return (
        this.candidate?.main_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    specialityIsOther() {
      return (
        this.candidate?.speciality_id?.label.includes("Other - not listed") ||
        false
      );
    },
    isOther() {
      return (
        (this.candidate?.other_areas_of_expertise_ids &&
          this.candidate?.other_areas_of_expertise_ids.some(
            ({ label }) => label === "Other - not listed"
          )) ||
        false
      );
    },
    isDoHAuthority() {
      return (
        this.payload?.eligibility_letter_available_for?.includes("DOH") || false
      );
    },
    isPGQualification() {
      const sortLevel =
        this.qualificationByCountryAndCandidateType?.find((v) =>
          v?.qualification_classification_name?.includes("First Degree")
        )?.qualification_classification_sort_level || null;
      return (
        (sortLevel &&
          this.candidate?.main_qualification_id
            ?.qualification_classification_sort_level >= sortLevel) ||
        false
      );
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.url = this.profilePhoto.value.croppedImageSrc;
        this.$refs.profilefileref.value = "";
        this.isImageSelected = false;
        this.fileData = {};
      }
    },
    payload() {
      if (this.options["subSpeciality"]?.length <= 0)
        this.changeFieldValues("sub_speciality_id", null);
      this.payload.main_qualification_speciality_id = this.payload.speciality_id
        ? this.payload.speciality_id
        : null;
    },
    "payload.candidate_type_id"() {
      this.changeFieldValues("main_qualification_country_id", null);
      this.changeFieldValues("other_areas_of_expertise_ids", []);
      this.changeFieldValues("other_areas_of_expertise_name", null);
    },
    "payload.sub_speciality_id"() {
      this.payload.main_qualification_sub_speciality_id =
        this.payload.sub_speciality_id;
    },
    "candidate.isRegisteredWhatsapp"() {
      this.candidate.isRegisteredWhatsapp
        ? ((this.candidate.whatsapp = this.candidate.phone),
          (this.payload.whatsapp = this.payload.phone))
        : this.changeFieldValues("whatsapp", null);
    },
    "candidate.preferred_contact_method"() {
      this.candidate.preferred_contact_method.some((el) => el.id == "whatsapp")
        ? (this.candidate.isRegisteredWhatsapp = true)
        : null;
    },
    "payload.country_id"() {
      this.changeFieldValues("has_emirates_id", null);
    },
    "candidate.has_emirates_id"() {
      this.changeFieldValues("emirates_id", null);
    },
    "candidate.local_license"() {
      this.changeFieldValues("license_number", null);
    },
  },
  mounted() {
    this.scrollTop();
    this.initQuickRegisterFetchOptions();
    this.fetchQualificationByCountryAndCandidateType();
    let isMobile = window.matchMedia(
      "only screen and (max-width: 400px)"
    ).matches;
    this.isMobile = isMobile;
    this.organisationID
      ? (this.payload["organisation_id"] = this.organisationID)
      : null;
    this.locationID ? (this.payload["location_id"] = this.locationID) : null;
  },
  methods: {
    ...mapActions([
      "initQuickRegisterFetchOptions",
      "showToast",
      "multipleFileUpload",
      "fetchQualificationByCountryAndCandidateType",
      "fetchQualificationByCountryAndCandidateTypeWithoutGetter",
      "fetchFirstQualificationByCountryAndCandidateType",
      "fetchHigherQualificationByCountryAndCandidateType",
      "scrollTop",
    ]),
    handleChangeMultiSelectAsString(name, value) {
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value
              .map((v) => {
                return v.id || v.code || v;
              })
              .join()
          : null,
      };
    },
    validateLicense(LicenseNumber) {
      this.licenseValidation.is_license_valid = null;
      const isParternError =
        this.$refs.license_number?.failedRules?.license_rule || false;
      const {
        dob: DateOfBirth,
        emirates_id: EmiratesID,
        passport_number: PassportNumber,
      } = this.candidate;
      if (LicenseNumber && DateOfBirth && isParternError === false) {
        let licencePayload = {};
        if (LicenseNumber)
          licencePayload = { ...licencePayload, LicenseNumber };
        if (DateOfBirth) licencePayload = { ...licencePayload, DateOfBirth };
        if (EmiratesID) licencePayload = { ...licencePayload, EmiratesID };
        if (PassportNumber)
          licencePayload = { ...licencePayload, PassportNumber };
        this.licenseValidation.isValidating = true;
      }
    },
    handleInputUpper(name, value) {
      const upperCase = _.toUpper(value);
      Vue.set(this.candidate, name, upperCase);
      Vue.set(this.payload, name, upperCase);
    },
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
      let hasError = {
        phone: "phone",
        whatsapp: "whatsapp",
      };
      if ((name === hasError.phone || hasError.whatsapp) && value === "") {
        this.clearFieldErrors(name, hasError);
      }
      let email_name = "email";
      if (name === "alternate_email") {
        Vue.set(this.candidate, email_name, value);
        Vue.set(this.payload, email_name, value);
      } else if (name === "license_number" && value) {
        setTimeout(() => {
          const isValid = this.validateLicense(value);
        }, 10);
      }
    },
    changeFieldValues(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleEmailChange(name, value) {
      if (
        !this.checkEmptyString(this.candidate["alternate_email"]) &&
        this.candidate["email"] != this.candidate["alternate_email"]
      ) {
        this.emailConfirmModal.isShowPopup = true;
      }
    },
    checkEmptyString(str) {
      if (str != null) return str.trim() === "";
      return true;
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.candidate, name, value);
      if (name == "qualified_date") {
        this.payload.main_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.main_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      if (name == "first_qualified_date") {
        this.payload.first_qualification_month_awarded = value
          ? m(value).month() + 1
          : null;
        this.payload.first_qualification_year_awarded = value
          ? m(value).year()
          : null;
        return;
      }
      Vue.set(this.payload, name, m(value).format("YYYY-MM-DD"));
    },
    handleChangeSelect(name, value) {
      if (
        name === "speciality_id" &&
        this.payload?.other_areas_of_expertise_ids &&
        this.payload?.other_areas_of_expertise_ids.includes(value?.code)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.candidate, name, value);
      let document_type = "document_type";
      let preferred_contact_method = "preferred_contact_method";
      if (name == preferred_contact_method) {
        let temp = "";
        value.forEach((ele) => {
          temp = temp ? temp + ", " + ele.id : ele.id;
        });
        value = temp;
      }
      if (name != document_type) {
        this.payload = {
          ...this.payload,
          [name]: value ? value.id || value.code || value : null,
        };
      }
      let hasError = {
        month_awarded: "main_qualification_month_awarded",
      };
      if (name == hasError.month_awarded && !value) {
        this.clearFieldErrors(name, hasError);
      }
      if (
        name === "candidate_type_id" ||
        name == "main_qualification_country_id"
      ) {
        this.fetchQualificationByCountryAndCandidateType({
          country_id: this.payload.main_qualification_country_id,
          candidate_type_id: this.payload.candidate_type_id,
        });
      }
      if (name === "main_qualification_id") {
        this.handleRadioNurseRegType("nurse_registration_type", false);
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      if (
        name === "other_areas_of_expertise_ids" &&
        value &&
        value.some(({ code }) => code === this.payload?.speciality_id)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message:
            "Speciality already exists! Please select another Speciality",
        });
        return;
      }
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
              return v.id || v.code || v;
            })
          : null,
      };
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.candidate, name, checked);
    },
    goToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    setCurrentData(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    handleRadioNurseRegType(name, value) {
      let val = [];
      if (value?.id || value?.code || value === true) {
        val = ["speciality_nurse_or_nurse_practitioner"];
      }
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    handleFile(event) {
      let docType = this.candidate.document_type;
      if (!docType) {
        let resume_docType = this.documentTypeExcPhoto.filter(
          ({ code }) => code === 1
        );
        docType = resume_docType?.length ? resume_docType[0] : [];
        let document_type = "document_type";
        Vue.set(this.candidate, document_type, docType);
      }
      let { name: filename } = event.target.files[0];
      let fileExt = filename.split(".").pop();
      const fileTypeString = this.fileType;
      let versionControl = {
        resume: "CV / Resume",
        coverLetter: "Cover Letter",
        surgicalLogbook: "Surgical Logbook",
      };
      let versionFound = false;
      if (this.documentExists(docType, filename)) {
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: "Document already exists! Please select another Document",
        });
      } else if (!fileTypeString.includes(fileExt)) {
        this.candidate.document_type = "";
        this.$refs.docfileref.value = "";
        this.showToast({
          class: "bg-danger text-white",
          message: `Allowed File Type ${fileTypeString}`,
        });
      } else {
        if (
          docType.label === versionControl.resume ||
          docType.label === versionControl.coverLetter ||
          docType.label === versionControl.surgicalLogbook
        ) {
          versionFound = this.uploadFiles.some(
            (el) => el.docType.code === docType.code
          );
        }
        this.fileUpload(docType, event, versionFound);
      }
    },
    fileUpload(docType, event, versionFound) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        let ext = event.target.files[0]?.name?.split(".").pop().toUpperCase();
        let fileData = { docType, file: event.target.files[0], fileExt: ext };
        if (versionFound) {
          for (let i = 0; i < this.uploadFiles.length; i++) {
            if (this.uploadFiles[i].docType.code === docType.code) {
              this.uploadFiles[i] = fileData;
            }
          }
        } else {
          this.uploadFiles.push(fileData);
        }
        this.$refs.docfileref.value = "";
        this.candidate.document_type = "";
      } else {
        this.$refs.docfileref.value = "";
        this.isFileError = true;
      }
    },
    handleConfirmPassword(name, value) {
      this[name] = value;
    },
    resetForm() {
      this.fileData = {};
      this.candidate = {
        preferred_contact_method: [],
        isRegisteredWhatsapp: false,
        local_license: false,
        dataflow_complete: false,
      };
      this.confirmPassword = null;
      this.$refs.phoneNo.resetPhoneno();
      if (this.$refs.whatsappNo) this.$refs.whatsappNo.resetPhoneno();

      (this.isFileError = false),
        (this.payload = {
          customer_id: this.customer,
          requires_login: true,
          volunteer: false,
          retired_clinician: false,
          local_license: false,
          dataflow_complete: false,
        });
      this.uploadFiles = [];
      this.document_id = "";
      (this.acquired_date = null),
        (this.isImageSelected = false),
        (this.isTermPrivacyChecked = false);
      this.licenseValidation = {
        is_license_valid: null,
        isValidating: false,
        isValid: null,
      };

      this.$refs.register.reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async onSubmit() {
      const isValid = await this.$refs.register.validate();
      const errors = Object.values(this.$refs.register.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        if (el?.previousSibling?.tagName === "INPUT")
          el.previousSibling.focus();
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.showToast({
          class: "bg-danger text-white",
          message: errors[0] || "please fill mandatory fields!",
        });
        return;
      } else if (this.isTermPrivacyChecked) {
        this.isLoading = false;
        this.submitCandidate();
      } else {
        this.termConfirmModal.isShowPopup = true;
      }
    },
    waitFor() {
      this.licenseValidation.is_license_valid = this.licenseValidation.isValid;
      if (this.licenseValidation.isValidating) {
        setTimeout(() => {
          this.waitFor();
        }, 50);
      } else {
        this.isLoading = false;
        if (
          this.payload?.local_license == false ||
          this.licenseValidation.is_license_valid ||
          (this.isDoHAuthority && this.candidate.license_number
            ? this.candidate.license_number.length === 0
            : true)
        ) {
          this.submitCandidate();
        } else {
          this.showToast({
            class: "bg-danger text-white",
            message:
              "The given license is not valid; please input a valid license or select license as NO!",
          });
          this.$refs?.license?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          const el = document.getElementsByName("license_number");
          if (el && el[0]) {
            el[0].focus();
          }
        }
      }
    },
    submitCandidate() {
      if (!this.payload?.sub_speciality_id)
        delete this.payload?.sub_speciality_id;

      if (this.candidate?.isRegisteredWhatsapp)
        this.payload.whatsapp = this.payload.phone;

      if (
        !this.candidate?.main_qualification_id?.label?.includes(
          "Other - not listed"
        )
      )
        delete this.payload.other_qualification_name;

      if (!this.candidate?.main_qualification_id?.allow_subject_input)
        delete this.payload.main_qualification_description;

      this.payload = {
        ...this.payload,
        privacy_acceptance: this.isTermPrivacyChecked,
      };

      if (
        !this.candidate?.speciality_id?.label?.includes("Other - not listed")
      ) {
        delete this.payload.other_speciality;
      }

      if (
        this.candidate?.other_areas_of_expertise_ids &&
        this.candidate?.other_areas_of_expertise_ids.some(
          ({ label }) => label === "Other - not listed"
        )
      ) {
        this.payload.other_areas_of_expertise_ids =
          this.candidate.other_areas_of_expertise_ids.map((v) => {
            return v.label === "Other - not listed"
              ? {
                  id: v.code || null,
                  other_name: this.payload?.other_areas_of_expertise_name,
                }
              : v.code;
          });
      }
      if (this.payload.other_areas_of_expertise_name)
        delete this.payload.other_areas_of_expertise_name;

      if (!this.isDoHAuthority) delete this.payload.license_number;

      if (!this.payload.local_license)
        delete this.payload.eligibility_letter_available_for;

      if (!this.payload.license_number) delete this.payload.license_number;

      if (!this.isNurse) {
        this.deleteFields([
          "other_areas_of_expertise_ids",
          "level_id",
          "nurse_registration_type",
        ]);
      }

      let finalPayload = { ...this.payload };
      if (finalPayload?.license_number) {
        const { is_license_valid } = this.licenseValidation;
        finalPayload = { ...finalPayload, is_license_valid };
      }

      let payload_data = {
        payload: finalPayload,
        docFiles: this.uploadFiles,
      };
      this.$emit("submit", payload_data);
    },
    selectedFile(event) {
      const file = event.target.files[0];
      this.isImageSelected = true;
      this.fileData = file;
    },
    closeModal() {
      this.$refs.profilefileref.value = "";
      this.fileData = {};
      this.isImageSelected = false;
    },
    arraytoComma(d) {
      return d.map((v) => "." + v).join(", ");
    },
    documentExists(docType, filename) {
      let files = [];
      this.uploadFiles.forEach((element) => {
        if (element.docType.code === docType.code) {
          files.push(element);
        }
      });
      let fileFound = files.some((el) => el.file.name === filename);
      return fileFound ? true : false;
    },
    deleteDocuments(fileName, index) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${fileName} ?`;
      this.document_id = index;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      let confirmDelete = "Confirm";
      if (action === confirmDelete) {
        this.uploadFiles.splice(this.document_id, 1);
      }
      this.document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
    emailmodalCallBack(action) {
      let confirmEmail = "Confirm";
      if (action === confirmEmail) {
        let alternet_email = "alternate_email";
        Vue.set(this.candidate, alternet_email, this.candidate["email"]);
        Vue.set(this.payload, alternet_email, this.candidate["email"]);
      }
      this.emailConfirmModal.isShowPopup = false;
    },
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
        ? n
        : new Array(width - n.length + 1).join(z) + n;
    },
    clearFieldErrors(name, hasError) {
      this.$nextTick(() => {
        if (name == hasError.phone) {
          this.$refs.phone.errors.clear;
          this.$refs.phone.reset();
        } else if (name == hasError.whatsapp) {
          this.$refs.whatsapp.errors.clear;
          this.$refs.whatsapp.reset();
        } else if (name == hasError.month_awarded) {
          this.$refs.month_awarded.errors.clear;
          this.$refs.month_awarded.reset();
        }
      });
    },
    showTermPopup() {
      let data = {
        document_ext: "PDF",
        link: "/doc/Talentfind_privacy_policy.pdf",
      };
      if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.modalTitle = "Terms and Privacy Policy";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    preview(data) {
      this.previewModal.modalTitle = "Document Preview";
      let src = URL.createObjectURL(data.file);
      if (
        data.fileExt === "PNG" ||
        data.fileExt === "JPG" ||
        data.fileExt === "JPEG" ||
        data.fileExt === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.pdf.ispdf = false;
        this.iframe.src = src;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.fileExt === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(src);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    termmodalCallBack(action) {
      this.termConfirmModal.isShowPopup = false;
    },
    deleteFields(deleteFields) {
      deleteFields.forEach((field) => {
        delete this.payload[field];
      });
    },
    camelize(str) {
      return _.startCase(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.hint {
  opacity: 0.7;
}
.img-width {
  width: 100%;
}
@media all and (max-width: 375px) {
  .text-center > .btn {
    width: 145px;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.registered-whatsapp {
  padding-top: 5px;
}
@media all and (max-width: 480px) {
  .registered-whatsapp {
    padding-top: 0%;
  }
}
.fs-12 {
  font-size: 12px;
}
</style>
